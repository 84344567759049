import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import Header from './components/header/header';
import Home from "./components/main/home";
import About from "./about";
function Modal({ children, shown, close }) {
  return shown ? (
      <div
        className="modal-content"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        
        <button onClick={close}>Close</button>
        {children}
      </div>
  ) : null;
}

function App() {
  const [modalShown, toggleModal] = React.useState(true);
  return (
    <div className="App">
      
      <Modal
       shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
      >
        <p id="one"></p><br/>
        <svg id="name" viewBox="0 0 1230 105" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1_3_4" maskUnits="userSpaceOnUse" x="0.272705" y="0.636353" width="1229" height="104"
            fill="black">
            <rect fill="white" x="0.272705" y="0.636353" width="1229" height="104" />
            <path
                d="M4.27271 99V5.90908H35.7273C43.0303 5.90908 49 7.22726 53.6363 9.86362C58.303 12.4697 61.7576 16 64 20.4545C66.2424 24.9091 67.3636 29.8788 67.3636 35.3636C67.3636 40.8485 66.2424 45.8333 64 50.3182C61.7879 54.803 58.3636 58.3788 53.7273 61.0454C49.0909 63.6818 43.1515 65 35.9091 65H13.3636V55H35.5454C40.5454 55 44.5606 54.1364 47.5909 52.4091C50.6212 50.6818 52.8182 48.3485 54.1818 45.4091C55.5757 42.4394 56.2727 39.0909 56.2727 35.3636C56.2727 31.6364 55.5757 28.303 54.1818 25.3636C52.8182 22.4242 50.606 20.1212 47.5454 18.4545C44.4848 16.7576 40.4242 15.9091 35.3636 15.9091H15.5454V99H4.27271Z" />
            <path
                d="M85.5227 99V5.90908H116.977C124.25 5.90908 130.22 7.1515 134.886 9.63635C139.553 12.0909 143.008 15.4697 145.25 19.7727C147.492 24.0757 148.614 28.9697 148.614 34.4545C148.614 39.9394 147.492 44.803 145.25 49.0454C143.008 53.2879 139.568 56.6212 134.932 59.0454C130.295 61.4394 124.371 62.6364 117.159 62.6364H91.7045V52.4545H116.795C121.765 52.4545 125.765 51.7273 128.795 50.2727C131.856 48.8182 134.068 46.7576 135.432 44.0909C136.826 41.3939 137.523 38.1818 137.523 34.4545C137.523 30.7273 136.826 27.4697 135.432 24.6818C134.038 21.8939 131.811 19.7424 128.75 18.2273C125.689 16.6818 121.644 15.9091 116.614 15.9091H96.7954V99H85.5227ZM129.341 57.1818L152.25 99H139.159L116.614 57.1818H129.341Z" />
            <path
                d="M171.216 99H159.398L193.58 5.90908H205.216L239.398 99H227.58L199.761 20.6364H199.034L171.216 99ZM175.58 62.6364H223.216V72.6364H175.58V62.6364Z" />
            <path
                d="M304.807 29.1818C304.261 24.5757 302.049 21 298.17 18.4545C294.292 15.9091 289.534 14.6364 283.898 14.6364C279.776 14.6364 276.17 15.303 273.08 16.6364C270.019 17.9697 267.625 19.803 265.898 22.1364C264.201 24.4697 263.352 27.1212 263.352 30.0909C263.352 32.5757 263.943 34.7121 265.125 36.5C266.337 38.2576 267.883 39.7273 269.761 40.9091C271.64 42.0606 273.61 43.0151 275.67 43.7727C277.731 44.5 279.625 45.0909 281.352 45.5454L290.807 48.0909C293.231 48.7273 295.928 49.6061 298.898 50.7273C301.898 51.8485 304.761 53.3788 307.489 55.3182C310.246 57.2273 312.519 59.6818 314.307 62.6818C316.095 65.6818 316.989 69.3636 316.989 73.7273C316.989 78.7576 315.67 83.303 313.034 87.3636C310.428 91.4242 306.61 94.6515 301.58 97.0454C296.58 99.4394 290.504 100.636 283.352 100.636C276.686 100.636 270.913 99.5606 266.034 97.4091C261.186 95.2576 257.367 92.2576 254.58 88.4091C251.822 84.5606 250.261 80.0909 249.898 75H261.534C261.837 78.5151 263.019 81.4242 265.08 83.7273C267.17 86 269.807 87.697 272.989 88.8182C276.201 89.9091 279.655 90.4545 283.352 90.4545C287.655 90.4545 291.519 89.7576 294.943 88.3636C298.367 86.9394 301.08 84.9697 303.08 82.4545C305.08 79.9091 306.08 76.9394 306.08 73.5454C306.08 70.4545 305.216 67.9394 303.489 66C301.761 64.0606 299.489 62.4848 296.67 61.2727C293.852 60.0606 290.807 59 287.534 58.0909L276.08 54.8182C268.807 52.7273 263.049 49.7424 258.807 45.8636C254.564 41.9848 252.443 36.9091 252.443 30.6364C252.443 25.4242 253.852 20.8788 256.67 17C259.519 13.0909 263.337 10.0606 268.125 7.90908C272.943 5.72726 278.322 4.63635 284.261 4.63635C290.261 4.63635 295.595 5.71211 300.261 7.86362C304.928 9.98484 308.625 12.8939 311.352 16.5909C314.11 20.2879 315.564 24.4848 315.716 29.1818H304.807Z" />
            <path
                d="M339.341 99H327.523L361.705 5.90908H373.341L407.523 99H395.704L367.886 20.6364H367.159L339.341 99ZM343.705 62.6364H391.341V72.6364H343.705V62.6364Z" />
            <path
                d="M450.75 99H422.023V5.90908H452.023C461.053 5.90908 468.78 7.77272 475.204 11.5C481.629 15.197 486.553 20.5151 489.977 27.4545C493.401 34.3636 495.114 42.6363 495.114 52.2727C495.114 61.9697 493.386 70.3182 489.932 77.3182C486.477 84.2879 481.447 89.6515 474.841 93.4091C468.235 97.1364 460.205 99 450.75 99ZM433.295 89H450.023C457.72 89 464.098 87.5151 469.159 84.5454C474.22 81.5758 477.992 77.3485 480.477 71.8636C482.962 66.3788 484.204 59.8485 484.204 52.2727C484.204 44.7576 482.977 38.2879 480.523 32.8636C478.068 27.4091 474.401 23.2273 469.523 20.3182C464.644 17.3788 458.568 15.9091 451.295 15.9091H433.295V89Z" />
            <path
                d="M600.932 29.1818C600.386 24.5757 598.174 21 594.295 18.4545C590.417 15.9091 585.659 14.6364 580.023 14.6364C575.901 14.6364 572.295 15.303 569.205 16.6364C566.144 17.9697 563.75 19.803 562.023 22.1364C560.326 24.4697 559.477 27.1212 559.477 30.0909C559.477 32.5757 560.068 34.7121 561.25 36.5C562.462 38.2576 564.008 39.7273 565.886 40.9091C567.765 42.0606 569.735 43.0151 571.795 43.7727C573.856 44.5 575.75 45.0909 577.477 45.5454L586.932 48.0909C589.356 48.7273 592.053 49.6061 595.023 50.7273C598.023 51.8485 600.886 53.3788 603.614 55.3182C606.371 57.2273 608.644 59.6818 610.432 62.6818C612.22 65.6818 613.114 69.3636 613.114 73.7273C613.114 78.7576 611.795 83.303 609.159 87.3636C606.553 91.4242 602.735 94.6515 597.705 97.0454C592.705 99.4394 586.629 100.636 579.477 100.636C572.811 100.636 567.038 99.5606 562.159 97.4091C557.311 95.2576 553.492 92.2576 550.705 88.4091C547.947 84.5606 546.386 80.0909 546.023 75H557.659C557.962 78.5151 559.144 81.4242 561.205 83.7273C563.295 86 565.932 87.697 569.114 88.8182C572.326 89.9091 575.78 90.4545 579.477 90.4545C583.78 90.4545 587.644 89.7576 591.068 88.3636C594.492 86.9394 597.205 84.9697 599.205 82.4545C601.205 79.9091 602.205 76.9394 602.205 73.5454C602.205 70.4545 601.341 67.9394 599.614 66C597.886 64.0606 595.614 62.4848 592.795 61.2727C589.977 60.0606 586.932 59 583.659 58.0909L572.205 54.8182C564.932 52.7273 559.174 49.7424 554.932 45.8636C550.689 41.9848 548.568 36.9091 548.568 30.6364C548.568 25.4242 549.977 20.8788 552.795 17C555.644 13.0909 559.462 10.0606 564.25 7.90908C569.068 5.72726 574.447 4.63635 580.386 4.63635C586.386 4.63635 591.72 5.71211 596.386 7.86362C601.053 9.98484 604.75 12.8939 607.477 16.5909C610.235 20.2879 611.689 24.4848 611.841 29.1818H600.932Z" />
            <path
                d="M635.466 99H623.648L657.83 5.90908H669.466L703.648 99H691.83L664.011 20.6364H663.284L635.466 99ZM639.83 62.6364H687.466V72.6364H639.83V62.6364Z" />
            <path
                d="M718.148 5.90908H731.602L763.239 83.1818H764.33L795.966 5.90908H809.42V99H798.875V28.2727H797.966L768.875 99H758.693L729.602 28.2727H728.693V99H718.148V5.90908Z" />
            <path
                d="M832.023 5.90908H845.477L877.114 83.1818H878.205L909.841 5.90908H923.295V99H912.75V28.2727H911.841L882.75 99H872.568L843.477 28.2727H842.568V99H832.023V5.90908Z" />
            <path d="M945.898 99V5.90908H1002.08V15.9091H957.17V47.3636H999.17V57.3636H957.17V89H1002.81V99H945.898Z" />
            <path
                d="M1096.22 5.90908V99H1085.31L1034.58 25.9091H1033.67V99H1022.4V5.90908H1033.31L1084.22 79.1818H1085.12V5.90908H1096.22Z" />
            <path
                d="M1182.41 35C1181.41 31.9394 1180.09 29.197 1178.45 26.7727C1176.85 24.3182 1174.92 22.2273 1172.68 20.5C1170.47 18.7727 1167.95 17.4545 1165.14 16.5454C1162.32 15.6364 1159.23 15.1818 1155.86 15.1818C1150.35 15.1818 1145.33 16.6061 1140.82 19.4545C1136.3 22.303 1132.71 26.5 1130.05 32.0454C1127.38 37.5909 1126.05 44.3939 1126.05 52.4545C1126.05 60.5151 1127.39 67.3182 1130.09 72.8636C1132.79 78.4091 1136.44 82.6061 1141.05 85.4545C1145.65 88.303 1150.83 89.7273 1156.59 89.7273C1161.92 89.7273 1166.62 88.5909 1170.68 86.3182C1174.77 84.0151 1177.95 80.7727 1180.23 76.5909C1182.53 72.3788 1183.68 67.4242 1183.68 61.7273L1187.14 62.4545H1159.14V52.4545H1194.59V62.4545C1194.59 70.1212 1192.95 76.7879 1189.68 82.4545C1186.44 88.1212 1181.95 92.5151 1176.23 95.6364C1170.53 98.7273 1163.98 100.273 1156.59 100.273C1148.35 100.273 1141.11 98.3333 1134.86 94.4545C1128.65 90.5757 1123.8 85.0606 1120.32 77.9091C1116.86 70.7576 1115.14 62.2727 1115.14 52.4545C1115.14 45.0909 1116.12 38.4697 1118.09 32.5909C1120.09 26.6818 1122.91 21.6515 1126.55 17.5C1130.18 13.3485 1134.48 10.1667 1139.45 7.95454C1144.42 5.74241 1149.89 4.63635 1155.86 4.63635C1160.77 4.63635 1165.35 5.37878 1169.59 6.86362C1173.86 8.31817 1177.67 10.3939 1181 13.0909C1184.36 15.7576 1187.17 18.9545 1189.41 22.6818C1191.65 26.3788 1193.2 30.4848 1194.05 35H1182.41Z" />
            <path d="M1225.17 5.90908V99H1213.9V5.90908H1225.17Z" />
        </mask>
        <path
            d="M4.27271 99V5.90908H35.7273C43.0303 5.90908 49 7.22726 53.6363 9.86362C58.303 12.4697 61.7576 16 64 20.4545C66.2424 24.9091 67.3636 29.8788 67.3636 35.3636C67.3636 40.8485 66.2424 45.8333 64 50.3182C61.7879 54.803 58.3636 58.3788 53.7273 61.0454C49.0909 63.6818 43.1515 65 35.9091 65H13.3636V55H35.5454C40.5454 55 44.5606 54.1364 47.5909 52.4091C50.6212 50.6818 52.8182 48.3485 54.1818 45.4091C55.5757 42.4394 56.2727 39.0909 56.2727 35.3636C56.2727 31.6364 55.5757 28.303 54.1818 25.3636C52.8182 22.4242 50.606 20.1212 47.5454 18.4545C44.4848 16.7576 40.4242 15.9091 35.3636 15.9091H15.5454V99H4.27271Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M85.5227 99V5.90908H116.977C124.25 5.90908 130.22 7.1515 134.886 9.63635C139.553 12.0909 143.008 15.4697 145.25 19.7727C147.492 24.0757 148.614 28.9697 148.614 34.4545C148.614 39.9394 147.492 44.803 145.25 49.0454C143.008 53.2879 139.568 56.6212 134.932 59.0454C130.295 61.4394 124.371 62.6364 117.159 62.6364H91.7045V52.4545H116.795C121.765 52.4545 125.765 51.7273 128.795 50.2727C131.856 48.8182 134.068 46.7576 135.432 44.0909C136.826 41.3939 137.523 38.1818 137.523 34.4545C137.523 30.7273 136.826 27.4697 135.432 24.6818C134.038 21.8939 131.811 19.7424 128.75 18.2273C125.689 16.6818 121.644 15.9091 116.614 15.9091H96.7954V99H85.5227ZM129.341 57.1818L152.25 99H139.159L116.614 57.1818H129.341Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M171.216 99H159.398L193.58 5.90908H205.216L239.398 99H227.58L199.761 20.6364H199.034L171.216 99ZM175.58 62.6364H223.216V72.6364H175.58V62.6364Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M304.807 29.1818C304.261 24.5757 302.049 21 298.17 18.4545C294.292 15.9091 289.534 14.6364 283.898 14.6364C279.776 14.6364 276.17 15.303 273.08 16.6364C270.019 17.9697 267.625 19.803 265.898 22.1364C264.201 24.4697 263.352 27.1212 263.352 30.0909C263.352 32.5757 263.943 34.7121 265.125 36.5C266.337 38.2576 267.883 39.7273 269.761 40.9091C271.64 42.0606 273.61 43.0151 275.67 43.7727C277.731 44.5 279.625 45.0909 281.352 45.5454L290.807 48.0909C293.231 48.7273 295.928 49.6061 298.898 50.7273C301.898 51.8485 304.761 53.3788 307.489 55.3182C310.246 57.2273 312.519 59.6818 314.307 62.6818C316.095 65.6818 316.989 69.3636 316.989 73.7273C316.989 78.7576 315.67 83.303 313.034 87.3636C310.428 91.4242 306.61 94.6515 301.58 97.0454C296.58 99.4394 290.504 100.636 283.352 100.636C276.686 100.636 270.913 99.5606 266.034 97.4091C261.186 95.2576 257.367 92.2576 254.58 88.4091C251.822 84.5606 250.261 80.0909 249.898 75H261.534C261.837 78.5151 263.019 81.4242 265.08 83.7273C267.17 86 269.807 87.697 272.989 88.8182C276.201 89.9091 279.655 90.4545 283.352 90.4545C287.655 90.4545 291.519 89.7576 294.943 88.3636C298.367 86.9394 301.08 84.9697 303.08 82.4545C305.08 79.9091 306.08 76.9394 306.08 73.5454C306.08 70.4545 305.216 67.9394 303.489 66C301.761 64.0606 299.489 62.4848 296.67 61.2727C293.852 60.0606 290.807 59 287.534 58.0909L276.08 54.8182C268.807 52.7273 263.049 49.7424 258.807 45.8636C254.564 41.9848 252.443 36.9091 252.443 30.6364C252.443 25.4242 253.852 20.8788 256.67 17C259.519 13.0909 263.337 10.0606 268.125 7.90908C272.943 5.72726 278.322 4.63635 284.261 4.63635C290.261 4.63635 295.595 5.71211 300.261 7.86362C304.928 9.98484 308.625 12.8939 311.352 16.5909C314.11 20.2879 315.564 24.4848 315.716 29.1818H304.807Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M339.341 99H327.523L361.705 5.90908H373.341L407.523 99H395.704L367.886 20.6364H367.159L339.341 99ZM343.705 62.6364H391.341V72.6364H343.705V62.6364Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M450.75 99H422.023V5.90908H452.023C461.053 5.90908 468.78 7.77272 475.204 11.5C481.629 15.197 486.553 20.5151 489.977 27.4545C493.401 34.3636 495.114 42.6363 495.114 52.2727C495.114 61.9697 493.386 70.3182 489.932 77.3182C486.477 84.2879 481.447 89.6515 474.841 93.4091C468.235 97.1364 460.205 99 450.75 99ZM433.295 89H450.023C457.72 89 464.098 87.5151 469.159 84.5454C474.22 81.5758 477.992 77.3485 480.477 71.8636C482.962 66.3788 484.204 59.8485 484.204 52.2727C484.204 44.7576 482.977 38.2879 480.523 32.8636C478.068 27.4091 474.401 23.2273 469.523 20.3182C464.644 17.3788 458.568 15.9091 451.295 15.9091H433.295V89Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M600.932 29.1818C600.386 24.5757 598.174 21 594.295 18.4545C590.417 15.9091 585.659 14.6364 580.023 14.6364C575.901 14.6364 572.295 15.303 569.205 16.6364C566.144 17.9697 563.75 19.803 562.023 22.1364C560.326 24.4697 559.477 27.1212 559.477 30.0909C559.477 32.5757 560.068 34.7121 561.25 36.5C562.462 38.2576 564.008 39.7273 565.886 40.9091C567.765 42.0606 569.735 43.0151 571.795 43.7727C573.856 44.5 575.75 45.0909 577.477 45.5454L586.932 48.0909C589.356 48.7273 592.053 49.6061 595.023 50.7273C598.023 51.8485 600.886 53.3788 603.614 55.3182C606.371 57.2273 608.644 59.6818 610.432 62.6818C612.22 65.6818 613.114 69.3636 613.114 73.7273C613.114 78.7576 611.795 83.303 609.159 87.3636C606.553 91.4242 602.735 94.6515 597.705 97.0454C592.705 99.4394 586.629 100.636 579.477 100.636C572.811 100.636 567.038 99.5606 562.159 97.4091C557.311 95.2576 553.492 92.2576 550.705 88.4091C547.947 84.5606 546.386 80.0909 546.023 75H557.659C557.962 78.5151 559.144 81.4242 561.205 83.7273C563.295 86 565.932 87.697 569.114 88.8182C572.326 89.9091 575.78 90.4545 579.477 90.4545C583.78 90.4545 587.644 89.7576 591.068 88.3636C594.492 86.9394 597.205 84.9697 599.205 82.4545C601.205 79.9091 602.205 76.9394 602.205 73.5454C602.205 70.4545 601.341 67.9394 599.614 66C597.886 64.0606 595.614 62.4848 592.795 61.2727C589.977 60.0606 586.932 59 583.659 58.0909L572.205 54.8182C564.932 52.7273 559.174 49.7424 554.932 45.8636C550.689 41.9848 548.568 36.9091 548.568 30.6364C548.568 25.4242 549.977 20.8788 552.795 17C555.644 13.0909 559.462 10.0606 564.25 7.90908C569.068 5.72726 574.447 4.63635 580.386 4.63635C586.386 4.63635 591.72 5.71211 596.386 7.86362C601.053 9.98484 604.75 12.8939 607.477 16.5909C610.235 20.2879 611.689 24.4848 611.841 29.1818H600.932Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M635.466 99H623.648L657.83 5.90908H669.466L703.648 99H691.83L664.011 20.6364H663.284L635.466 99ZM639.83 62.6364H687.466V72.6364H639.83V62.6364Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M718.148 5.90908H731.602L763.239 83.1818H764.33L795.966 5.90908H809.42V99H798.875V28.2727H797.966L768.875 99H758.693L729.602 28.2727H728.693V99H718.148V5.90908Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M832.023 5.90908H845.477L877.114 83.1818H878.205L909.841 5.90908H923.295V99H912.75V28.2727H911.841L882.75 99H872.568L843.477 28.2727H842.568V99H832.023V5.90908Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path d="M945.898 99V5.90908H1002.08V15.9091H957.17V47.3636H999.17V57.3636H957.17V89H1002.81V99H945.898Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M1096.22 5.90908V99H1085.31L1034.58 25.9091H1033.67V99H1022.4V5.90908H1033.31L1084.22 79.1818H1085.12V5.90908H1096.22Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path
            d="M1182.41 35C1181.41 31.9394 1180.09 29.197 1178.45 26.7727C1176.85 24.3182 1174.92 22.2273 1172.68 20.5C1170.47 18.7727 1167.95 17.4545 1165.14 16.5454C1162.32 15.6364 1159.23 15.1818 1155.86 15.1818C1150.35 15.1818 1145.33 16.6061 1140.82 19.4545C1136.3 22.303 1132.71 26.5 1130.05 32.0454C1127.38 37.5909 1126.05 44.3939 1126.05 52.4545C1126.05 60.5151 1127.39 67.3182 1130.09 72.8636C1132.79 78.4091 1136.44 82.6061 1141.05 85.4545C1145.65 88.303 1150.83 89.7273 1156.59 89.7273C1161.92 89.7273 1166.62 88.5909 1170.68 86.3182C1174.77 84.0151 1177.95 80.7727 1180.23 76.5909C1182.53 72.3788 1183.68 67.4242 1183.68 61.7273L1187.14 62.4545H1159.14V52.4545H1194.59V62.4545C1194.59 70.1212 1192.95 76.7879 1189.68 82.4545C1186.44 88.1212 1181.95 92.5151 1176.23 95.6364C1170.53 98.7273 1163.98 100.273 1156.59 100.273C1148.35 100.273 1141.11 98.3333 1134.86 94.4545C1128.65 90.5757 1123.8 85.0606 1120.32 77.9091C1116.86 70.7576 1115.14 62.2727 1115.14 52.4545C1115.14 45.0909 1116.12 38.4697 1118.09 32.5909C1120.09 26.6818 1122.91 21.6515 1126.55 17.5C1130.18 13.3485 1134.48 10.1667 1139.45 7.95454C1144.42 5.74241 1149.89 4.63635 1155.86 4.63635C1160.77 4.63635 1165.35 5.37878 1169.59 6.86362C1173.86 8.31817 1177.67 10.3939 1181 13.0909C1184.36 15.7576 1187.17 18.9545 1189.41 22.6818C1191.65 26.3788 1193.2 30.4848 1194.05 35H1182.41Z"
            stroke="white" stroke-width="8" mask="url(#path-1-outside-1_3_4)" />
        <path d="M1225.17 5.90908V99H1213.9V5.90908H1225.17Z" stroke="white" stroke-width="8"
            mask="url(#path-1-outside-1_3_4)" />
    </svg>
    <p id="one">Hi, my name is</p>
      </Modal>
      <Router>
      <Header title="Prasad Sammengi"  />
      <h6></h6>
      
        <Routes>
            <Route index element={<Navigate to="/about" replace />} /> 
            <Route path="/home" element={<Home message="Experienced Java developer with a strong track record of
            delivering successful projects. Proficient in Java 8 and above, with a
            solid understanding of design patterns and object-oriented programming. 
            Skilled in developing REST APIs and experience with agile development methodologies. 
            Seeking a challenging Java development role where I can utilize my skills and continue 
            learning and growing as a developer."       
            note="@ps"/>} />
            <Route path="/about" element={<About about="checking"/>}/>
      
          </Routes>
      </Router>
    </div>
    
  );
}

export default App;
